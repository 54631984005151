import { useEffect } from "react";
import "./aboutUsPage.css";
import "./commonJeev.css";
import AboutUsImage from "../assests/jeevwebsite/images/aboutUsImage.png";
import UnderLineImage from "../assests/jeevwebsite/icons/underLine.svg";
import VinayakProfile from "../assests/jeevwebsite/images/profile/vinayakProfile.png";
import LinkedInSquareIcon from "../assests/jeevwebsite/icons/linkedinSquare.png";
import HosClinicalBottomRightIcon from "../assests/jeevwebsite/icons/hosClinicalBottomRight.png";
import OurTeamTopLeftIcon from "../assests/jeevwebsite/icons/ourTeamTopLeftIcon.png";
import ShibanProfile from "../assests/jeevwebsite/images/profile/shibaniProfile.png";
import MohanProfile from "../assests/jeevwebsite/images/profile/mohanProfile.png";
import HarishProfile from "../assests/jeevwebsite/images/profile/harishProfile.png";
import SajeerProfile from "../assests/jeevwebsite/images/profile/sajeerProfile.png";
import SureshProfile from "../assests/jeevwebsite/images/profile/sureshProfile.png";
import PrashanthProfile from "../assests/jeevwebsite/images/profile/prashanthProfile.png";
import ManjuProfile from "../assests/jeevwebsite/images/profile/manjuProfile.png";
import ShreyasProfile from "../assests/jeevwebsite/images/profile/shreyasProfile.png";

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const OurTeamData = [
    {
      image: VinayakProfile,
      name: "Vinayaka Siddesh",
      role: "Founder & CEO",
      linkedInLink: "http://linkedin.com/in/vinayaka-siddesh-680b5716",
    },
    {
      image: MohanProfile,
      name: "Dr. Mohan Kumar G",
      role: "Co-Founder & Mentor",
      linkedInLink: "https://www.linkedin.com/in/dr-mohan-kumar-g-17a75a94/",
    },
    {
      image: HarishProfile,
      name: "Harish Shivanna",
      role: "VP Technology",
      linkedInLink: "https://www.linkedin.com/in/harishshivanna/",
    },
    // {
    //   image: SajeerProfile,
    //   name: "Dr. Sajeer A T",
    //   role: "Clinical Product Manager",
    //   linkedInLink: "https://www.linkedin.com/in/sajeer-at-65b11526b/",
    // },
  ];

  const mentorsData = [
    {
      image: VinayakProfile,
      name: "Vinayaka Siddesh",
      role: "Founder & CEO",
      linkedInLink: "http://linkedin.com/in/vinayaka-siddesh-680b5716",
    },
    {
      image: MohanProfile,
      name: "Dr. Mohan Kumar G",
      role: "Physician",
      linkedInLink: "https://www.linkedin.com/in/dr-mohan-kumar-g-17a75a94/",
    },
    {
      image: SureshProfile,
      name: "Suresh Alla",
      role: "Healthcare Techpreneur",
      linkedInLink: "https://www.linkedin.com/in/sureshbiomedical/",
    },
    {
      image: PrashanthProfile,
      name: "Dr. Prashanth Kanavi",
      role: "Physician",
      linkedInLink:
        "https://www.linkedin.com/in/dr-prashanth-kanavi-863424139/",
    },
    {
      image: ShibanProfile,
      name: "Dr. Shibani Alva",
      role: "Paediatrician",
      linkedInLink: "https://www.linkedin.com/in/dr-shibani-alva-9b3882155/",
    },
    {
      image: ManjuProfile,
      name: "Dr. Manju B Reddy",
      role: "Anaesthesiologist & Intensivist",
      linkedInLink: "https://www.linkedin.com/in/manju-bhargava-reddy-72b0513/",
    },
    {
      image: ShreyasProfile,
      name: "Dr. Shreyas Alva",
      role: "Orthopedic Surgeon",
      linkedInLink: "https://www.linkedin.com/in/dr-shreyas-alva-6b9a6a230/",
    },
  ];

  const disableRightClick = (event) => {
    event.preventDefault();
  };

  return (
    <div className="h-full font_family">
      <div className="home_page_image_conatiner">
        <div className="flex flex-col text-center pt-4">
          <span className="lg:text-[38px] md:text-[28px] text-[22px] primary_color font-semibold">
            Empowering Healthcare Providers
          </span>
          <span className="lg:text-[20px] md:text-[16] text-[12px] secondary_color font-medium sm:pt-4 pt-2 px-5">
            Enabling Best Quality Care At Affordable Cost
          </span>
        </div>
        <div className="flex justify-center mt-4 sm:mt-6">
          <img
            src={AboutUsImage}
            className="aboutUs_image w-full h-full"
            alt="AboutUsImage"
            onContextMenu={disableRightClick}
          />
        </div>
      </div>

      <div className="hospital_clinicians_conatiner relative">
        <div className="absolute bottom-0 right-0">
          <img
            className="HosClinicalIcon"
            src={HosClinicalBottomRightIcon}
            alt="HosClinicalBottomRightIcon"
            onContextMenu={disableRightClick}
          />
        </div>
        <div className="flex flex-col py-5 sm:py-10 pt-6 sm:pt-12">
          <span className="lg:text-[24px] md:text-[20px] sm:text-[16px] font-semibold tertiary_color">
            Hospitals
          </span>
          <span className="lg:text-[20px] md:text-[16px] text-[14px] font-medium secondary_color py-1">
            Unorganised management and Idle infrastructure & staff costs
            impacting quality & cost of care
          </span>
          <ul className="primary_color lg:text-[18px] md:text-[16px] text-[14px] font-normal list-disc ps-8">
            <li>
              Around 44,000 Private Hospitals serving around 70% Patients in
              India
            </li>
            <li>
              Lack of entrepreneurial strategies as the hospital owners are
              predominantly medical professionals, who are service-oriented
            </li>
            <li>
              Bed occupancy, infrastructure & staff utilisation rate less than
              50%
            </li>
          </ul>
        </div>
        <div className="flex flex-col pb-6 sm:pb-10">
          <span className="lg:text-[24px] md:text-[20px] text-[16px] font-semibold tertiary_color">
            Clinicians
          </span>
          <span className="lg:text-[20px] md:text-[16px] text-[14px] font-medium secondary_color py-1">
            Clinical errors and Clinician burnouts affecting quality & cost of
            care
          </span>
          <ul className="primary_color lg:text-[18px] md:text-[14px] text-[12px] font-normal list-disc ps-8">
            <li>
              Unconnected & unstandardised workflows and scattered data in
              Patient Case Sheets are prone to clinical errors
            </li>
            <li>
              Tedious & laborious Clinical Documentation & Compliances adding
              administrative burdens for the Clinicians
            </li>
            <li>
              Conservative & legacy Electronic Medical Records (EMRs)
              significantly contributing to Clinician Burnout
            </li>
          </ul>
        </div>
        <div className="pb-3">
          <span className="tertiary_color lg:text-[24px] md:text-[20px] text-[16px] font-medium">
            We at jeev.ai are on a mission to transform hospital management and
            patient care with Technology & AI
          </span>
        </div>

        {/* <div className="flex justify-center pt-4 sm:pt-10 pb-3">
          <div className="text-center">
            <span className="lg:text-[32px] md:text-[25px] text-[18px] tertiary_color font-semibold">
              Our Team
            </span>
            <div className="w-full py-2">
              <img
                src={UnderLineImage}
                alt="UnderLineImage"
                onContextMenu={disableRightClick}
              />
            </div>
          </div>
        </div> */}
      </div>

      {/* <div className="our_team_conatiner relative">
        <div className="absolute top-0 left-0 hidden sm:block">
          <img
            src={OurTeamTopLeftIcon}
            alt="OurTeamTopLeftIcon"
            className="OurTeamTopLeftIcon"
            onContextMenu={disableRightClick}
          />
        </div>
        <div className="flex justify-center lg:px-10">
          <div className="flex gap-6 scroll_bar">
            {OurTeamData?.map((item, index) => (
              <div key={index} className="profile_card text-center">
                <div className="flex justify-center py-6 profile_card_padding ">
                  <img
                    src={item?.image}
                    alt="profileImage"
                    onContextMenu={disableRightClick}
                    className="profile_image"
                  />
                </div>
                <div className="flex flex-col">
                  <span className="profile_name font-medium text-[18px]">
                    {item?.name}
                  </span>
                  <span className="primary_color text-[14px] font-normal py-2">
                    {item?.role}
                  </span>
                  <a
                    href={item?.linkedInLink}
                    target="_blank"
                    className="flex justify-center"
                  >
                    <img
                      src={LinkedInSquareIcon}
                      alt="LinkedInSquareIcon"
                      onContextMenu={disableRightClick}
                      width={20}
                      height={20}
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      <div className="mentors_conatiner">
        <div className="flex justify-center pt-4 sm:pt-10">
          <div className="text-center">
            <span className="lg:text-[32px] md:text-[25px] text-[18px] tertiary_color font-semibold">
              Our Team
            </span>
            <div className="w-full py-2">
              <img
                src={UnderLineImage}
                alt="UnderLineImage"
                onContextMenu={disableRightClick}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center lg:px-10">
          <div className="flex gap-6 scroll_bar">
            {mentorsData?.map((item, index) => (
              <div key={index} className="profile_card_mentor text-center">
                <div className="flex justify-center py-6 profile_card_padding">
                  <img
                    src={item?.image}
                    alt="profileImage"
                    onContextMenu={disableRightClick}
                    className="profile_image"
                  />
                </div>
                <div className="flex flex-col">
                  <span className="profile_name font-medium text-[18px]">
                    {item?.name}
                  </span>
                  <span className="primary_color text-[14px] font-normal py-2">
                    {item?.role}
                  </span>
                  <a
                    href={item?.linkedInLink}
                    target="_blank"
                    className="flex justify-center"
                  >
                    <img
                      src={LinkedInSquareIcon}
                      alt="LinkedInSquareIcon"
                      onContextMenu={disableRightClick}
                      width={20}
                      height={20}
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
