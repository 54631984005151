import { useState, useEffect } from "react";
import "./contactUs.css";
import "./commonJeev.css";
import img1 from "../assests/jeevwebsite/images/contactusimage.png";
import axios from "axios";
import RightLogoIcon from "../assests/jeevwebsite/images/tick.png";

const ContactUsPage = () => {
  const [object, setObject] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submit, setSubmit] = useState(false);
  const [firstError, setFirstError] = useState(false);
  const [phoneError, setphoneError] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [submit]);
  const submitForm = async (e) => {
    e.preventDefault();
    let { firstname, lastname, email, phone, message } = object;
    let refobj = { firstname, lastname, email, phone, message };

    if (phone == "") {
      setphoneError(true);
    }
    if (firstname == "") {
      setFirstError(true);
    }

    try {
      if (phone == "" || firstname == "") {
        setError(true);
      } else {
        setSubmit(true);

        await axios.post(
          "https://jeevcontactus.azurewebsites.net/api/httpTrigger1",
          refobj
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setObject((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  const disableRightClick = (event) => {
    event.preventDefault();
  };

  return (
    <div className="h-full font_family">
      <div className="mains px-4 md:px-8 lg:px-10 ">
        <div className="px-0 sm:px-5">
          {!submit ? (
            <>
              <div className="flex justify-center py-3">
                <span className="getintouch mt-2 lg:text-[38px] md:text-[38px] text-[30px]">
                  Get in touch
                </span>
              </div>
              <form onSubmit={submitForm}>
                <div className="mb-4 flex flex-wrap">
                  <div className="lg:w-5/12 w-full">
                    <div className="px-0 mt-0 lg:mt-5 md:mt-0 flex justify-center">
                      <img
                        src={img1}
                        className="conimg mt-0"
                        onContextMenu={disableRightClick}
                      />
                    </div>
                  </div>
                  <div className="md:w-full lg:w-6/12 w-full">
                    <span className="mt-0 ps-2 getintouch1 lg:text-[38px] md:text-[28px] text-[22px]">
                      Get in touch
                    </span>
                    <div className="flex flex-wrap mt-4">
                      <div className="md:w-6/12 w-full mt-4 md:pe-4 px-2">
                        <label className="henu" htmlFor="sq">
                          First Name
                        </label>
                        <div>
                          <input
                            name="firstname"
                            type="text"
                            id="sq"
                            required
                            className={`${
                              firstError ? "error_border" : "border_color"
                            } haa w-full ps-2`}
                            onChange={(e) => {
                              handleChange(e);
                              setFirstError(false);
                            }}
                          />
                        </div>
                      </div>

                      <div className="md:w-6/12 w-full mt-4 md:ps-4 px-2">
                        <label className="henu" htmlFor="slq">
                          Last Name
                        </label>
                        <div>
                          <input
                            name="lastname"
                            type="text"
                            id="slq"
                            className="haa w-full ps-2 border_color"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-wrap  lg:mt-2">
                      <div className="md:w-6/12 w-full mt-4 md:pe-4 px-2">
                        <label className="henu" htmlFor="sql">
                          Phone Number
                        </label>
                        <div>
                          <input
                            name="phone"
                            type="text"
                            id="slhq"
                            required
                            className={`${
                              phoneError ? "error_border" : "border_color"
                            } haa w-full ps-2`}
                            onChange={(e) => {
                              handleChange(e);
                              setphoneError(false);
                            }}
                          />
                        </div>
                      </div>

                      <div className="md:w-6/12 w-full mt-4 md:ps-4 px-2">
                        <label className="henu" htmlFor="slhq">
                          Email ID
                        </label>
                        <div>
                          <input
                            name="email"
                            type="email"
                            required
                            id="slhq"
                            className="haa w-full ps-2 border_color"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:mt-6 mt-4 px-2">
                      <label htmlFor="sqlf" className="henu">
                        Message
                      </label>
                      <div className="w-full">
                        <textarea
                          name="message"
                          id="sqlf"
                          className="messagetextare w-full ps-3 pt-2"
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                    <button className="gsw mt-4 mx-2" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <div className="thank_you pt-7">
              <div className="flex flex-col py-4 text-center mt-5">
                <div className="flex justify-center">
                  <img
                    className="RightLogo"
                    src={RightLogoIcon}
                    alt="RightLogoIcon"
                  />
                </div>
                <div>
                  <span className="lg:text-[38px] md:text-[24] text-[18px] font-semibold py-3">
                    <span>Thank you for reaching us.</span>
                  </span>
                </div>
                <div className="member">
                  <span className="lg:text-[24px] md:text-[18px] text-[14px] font-light py-3 text-center px-5">
                    Our Jeev team member will get in touch with you soon.
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="pt-10 px-5 xl:px-40 lg:px-30 md:px-10">
          <div className="flex flex-wrap justify-between pt-0 pb-3">
            <div className=" mb-3">
              <span className="lg:text-[24px] md:text-[20px] text-[18px] corprateofficetext">
                R&D Office
              </span>
              <div className="flex flex-col pt-1">
                <span className="lg:text-[18px] md:text-[18px] text-[14px] addressfont">
                  No 636, 1st floor, A1 block
                </span>
                <span className="lg:text-[18px] md:text-[18px] text-[14px] addressfont">
                  Vijayanagar 3rd stage,Mysuru
                </span>
                <span className="lg:text-[18px] md:text-[18px] text-[14px] addressfont">
                  Karnataka, India-570030
                </span>
              </div>
            </div>
            <div className="mb-3 xl:ms-10 lg:ms-5 ">
              <span className="lg:text-[24px] md:text-[20px] text-[18px] corprateofficetext">
                Corporate Office
              </span>
              <div className="flex flex-col pt-1">
                <span className="lg:text-[18px] md:text-[18px] text-[14px] addressfont">
                  Sai Samrudhi, No 11(Old657),
                </span>
                <span className="lg:text-[18px] md:text-[18px] text-[14px] addressfont">
                  11th C Main, 5th Block,
                </span>
                <span className="lg:text-[18px] md:text-[18px] text-[14px] addressfont">
                  Jayanagar, Bangalore,
                </span>
                <span className="lg:text-[18px] md:text-[18px] text-[14px] addressfont">
                  Karnataka, India-570041
                </span>
              </div>
            </div>
            <div className="lg:w-3/12 mb-1">
              <div className="column">
                <div>
                  <span className="lg:text-[24px] md:text-[20px] text-[18px] corprateofficetext">
                    For Enquiry
                  </span>
                </div>
                <div className="pt-1">
                  <span className="lg:text-[18px] md:text-[18px] text-[14px] addressfont">
                    <a href="mailto:hello@jeev.ai">hello@jeev.ai</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
