const componentConstants = {
  FOLLOW_US: "Follow us",
  FOOTER_TEXT_ONE: "Empowering Healthcare Providers",
  FOOTER_TEXT_TWO: "Want to know more about us?",
  CONTACT_US: "Contact us",
  COPYRIGHT_MESSAGE: "© Copyright 2024 Jeev.ai. All rights reserved.",
  NO_DATA_FOUND_TEXT: "There is no data found",
  COMPANY_NAME: "jeev.ai",
};

export default componentConstants;
