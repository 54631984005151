import React, { useEffect, useState } from "react";
import "./layout.css";
import "../pages/commonJeev.css";
import LogoImage from "../assests/jeevwebsite/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import SideBarOpenIcon from "../assests/jeevwebsite/icons/sidebarOpen.svg";
import SideBarCloseIcon from "../assests/jeevwebsite/icons/sidebarClose.svg";
import publicRouteName from "../constants/publicRouteName";
import constantsObject from "../constants";
import componentConstants from "./constants";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  useEffect(() => {
    if (isOffcanvasOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOffcanvasOpen]);

  const disableRightClick = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="flex items-center justify-between sticky top-0 z-10 navbar_padding navbar_container font_family">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            navigate(publicRouteName.JEEV_WEBSITE);
            window.scrollTo(0, 0);
          }}
        >
          <img
            src={LogoImage}
            alt="LogoImage"
            className="LogoImage"
            onContextMenu={disableRightClick}
          />
          <span className="sm:font-semibold font-medium primary_color lg:text-[28px] md:text-[24px] text-[22px] ps-0 jeev_text">
            {componentConstants.COMPANY_NAME}
          </span>
        </div>

        <div className="block sm:hidden">
          <button
            className="border-none bg-none pe-2"
            type="button"
            onClick={() => setIsOffcanvasOpen(true)}
          >
            <img
              src={SideBarOpenIcon}
              width={20}
              height={20}
              alt="SideBarOpenIcon"
              onContextMenu={disableRightClick}
            />
          </button>
        </div>

        <div
          className={`fixed inset-0 z-50 transform ${
            isOffcanvasOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300`}
          id="offcanvasRight"
        >
          <div
            className="fixed inset-0 bg-[black] bg-opacity-50 backdrop-blur-sm"
            onClick={() => setIsOffcanvasOpen(false)}
          ></div>
          <div className="absolute right-0 h-full w-50 p-4 flex flex-col gap-3 bg-[#fff2e6]">
            <div className="flex justify-end p-2 py-4">
              <button
                className="border-none bg-none"
                type="button"
                onClick={() => setIsOffcanvasOpen(false)}
              >
                <img
                  src={SideBarCloseIcon}
                  width={20}
                  height={20}
                  alt="SideBarCloseIcon"
                  onContextMenu={disableRightClick}
                />
              </button>
            </div>
            {[
              publicRouteName.JEEV_WEBSITE,
              publicRouteName.ABOUT_PAGE,
              publicRouteName.CONTACT,
            ].map((path, index) => (
              <button
                key={index}
                className={`text-[16px] font-normal w-full text-left ${
                  location.pathname === path
                    ? "tertiary_color font-semibold"
                    : "primary_color"
                }`}
                onClick={() => {
                  navigate(path);
                  window.scrollTo(0, 0);
                  setIsOffcanvasOpen(false);
                }}
                type="button"
              >
                <div
                  className={`navbar_btn ${
                    location.pathname === path ? " active_tab" : ""
                  }`}
                >
                  {path === publicRouteName.JEEV_WEBSITE
                    ? constantsObject.HOME
                    : path === publicRouteName.ABOUT_PAGE
                    ? constantsObject.ABOUT_US
                    : path === publicRouteName.CONTACT
                    ? constantsObject.CONTACT_US
                    : ""}
                </div>
              </button>
            ))}
          </div>
        </div>

        <div className="hidden sm:flex items-center">
          {[
            publicRouteName.JEEV_WEBSITE,
            publicRouteName.ABOUT_PAGE,
            publicRouteName.CONTACT,
          ].map((path, index) => (
            <button
              key={index}
              className={`text-[16px] font-normal md:px-6 px-3 py-2 ${
                location.pathname === path
                  ? "tertiary_color font-semibold "
                  : "primary_color"
              }`}
              onClick={() => {
                navigate(path);
                window.scrollTo(0, 0);
              }}
              type="button"
            >
              <span
                className={`navbar_btn ${
                  location.pathname === path ? "active_tab" : ""
                }`}
              >
                {path === publicRouteName.JEEV_WEBSITE
                  ? constantsObject.HOME
                  : path === publicRouteName.ABOUT_PAGE
                  ? constantsObject.ABOUT_US
                  : path === publicRouteName.CONTACT
                  ? constantsObject.CONTACT_US
                  : ""}
              </span>
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default Navbar;
