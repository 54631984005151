import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "../pages/homePage";
import AboutUsPage from "../pages/aboutUsPage";
import ContactUsPage from "../pages/contactUsPage";
import Navbar from "../layout/navbar";
import Footer from "../layout/footer";
import MainRouter from "./MainRouter";
import PageNotFound from "../pages/PageNotFound";

const Index = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route element={<MainRouter />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default Index;
