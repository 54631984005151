import { Outlet } from "react-router-dom";
import Navbar from "../layout/navbar";
import Footer from "../layout/footer";

const MainRouter = () => {
  return (
    <div className="w-full">
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainRouter;
